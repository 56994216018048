#homeSections {
  .category {
    padding: 10px 20px;
    background: #f0f0f0;
  }

  .child-row,
  .header-row {
    display: flex;
    justify-content: space-between;
    p {
      width: 50%;
    }
  }

  .table {
    width: 100%;

    div:nth-child(even) {
      background: #f8f8f8;
    }
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .action-items {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .action-item {
    max-width: 90px;
    text-align: center;
    margin-right: 5px;
    justify-content: center;
  }
  .wrapper {
    &__baners {
      margin-top: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &__3 {
        width: 30%;
      }
    }
  }
  .baners-3 {
    width: 33%;
  }
}
.wrapper {
  &__baners {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .button-wrapper {
      margin-bottom: 15px;
    }
    &__3 {
      width: 30%;
      .MuiFormControl-root {
        width: 100% !important;
      }
    }
    &__1 {
      width: 100%;
      .MuiFormControl-root {
        width: 100% !important;
      }
    }
    &__2 {
      width: 48%;
      .MuiFormControl-root {
        width: 100% !important;
      }
    }
    &__3-4 {
      width: 50%;
      .MuiFormControl-root {
        width: 100% !important;
      }
    }
    &__1-4 {
      width: 50%;
      .MuiFormControl-root {
        width: 100% !important;
      }
    }
  }
}
.error-text {
  color: red;
  margin-bottom: 5px;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #fb923c !important;
}
