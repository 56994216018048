.discount-item-size,
.baner-size,
.treebaners-size {
  @media screen and (max-width: 768px) {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
}

.treebaners-size {
  @media screen and (max-width: 425px) {
    display: flex;
    flex-wrap: wrap;
    div {
      margin-bottom: 5px;
      width: 100% !important;
    }
  }
}

.product-and-baner-size {
  @media screen and (max-width: 768px) {
    height: 90px !important;
  }
}
.two_baners {
  &__0 {
    padding-right: 15px;
  }
  &__0 {
    margin-left: 5px;
  }
  @media screen and (max-width: 768px) {
    &__0 {
      padding-right: 0;
    }
    &__0 {
      margin-left: 0;
    }
  }
}

.product-discounts-mobile {
  .discount-item-size {
    width: 30%;
  }
  @media screen and (max-width: 425px) {
    flex-wrap: wrap;
    .discount-item-size {
      margin-bottom: 5px;
      width: 100%;
    }
  }
}

.box-shadow {
  box-shadow: 0px 0px 9px -5px #251e18 !important;
}
