.alert-message {
  button {
    width: max-content !important;
  }
}
.img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: auto;
    height: auto;
    max-height: 60%;
    max-width: 50%;
  }
}
