.sort-by-wrapper,
.sort-by-label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.sort-by-wrapper {
  .MuiSelect-select,
  .MuiList-root,
  .MuiListItem-root {
    text-align: center;
    justify-content: center !important;
  }
}

.list-item-sort-by {
  justify-content: center !important;
}
