.category {
  padding: 10px 20px;
  background: #f0f0f0;
}

.child-row,
.header-row {
  display: flex;
  justify-content: space-between;
}
.header-row {
  padding: 10px 20px;
  font-weight: bold;
}
.table {
  div:nth-child(even) {
    background: #f8f8f8;
    .action-item {
      background: #f8f8f8;
    }
  }
  div:nth-child(odd) {
    .action-item {
      background: #f0f0f0;
    }
  }
}
#upload-modal {
  .action-item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid black;
    padding: 0px 5px;
    border-radius: 10px;
  }
  .first-action {
    margin-right: 15px;
  }
  .upload-wrapper {
    height: 300px;
    display: flex;
    flex-direction: row;
  }
  .upload-item {
    width: 50%;
    height: 100%;
    padding: 10px;
  }

  .upload-item-full-size {
    width: 100%;
    padding: 10px;
  }
  h1 {
    font-size: 19px;
  }
  h2 {
    font-size: 15px;
  }
  .header {
    text-align: center;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .MuiBox-root {
    outline: none !important;
  }
  .upload-container {
    height: 190px;
    border: 1px dashed lightblue;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .box-header {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: lightgray;
  }
  .hidden-input {
    display: none;
  }
  .img-preview {
    max-width: 280px;
    max-height: 180px;
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    &.end-container {
      justify-content: flex-end;
    }
    button {
      background: red;
      color: #fff;
      &.primary {
        background: blue;
      }
    }
  }
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
  &:before {
    display: none;
  }
}
