.sidebar_height{
    height: calc(100vh - 70px);
    box-shadow: 25px 0px 20px -20px rgba(0,0,0,0.45);
    .sidebar_content{
        background-color: white;
        .pro-icon{
            background-color: white;
        }

    }
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item{
    background-color: white;
}