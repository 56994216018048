#news {
  .search {
    margin: 20px;
    height: 40px;
    width: 300px;
    display: flex;
    outline: none;
    padding: 0 10px;
    border-radius: 10px;
  }

  .input-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 20px;
  }

  .newsletter,
  .nav_menu_newsletter {
    grid-template-columns: 20% 60% 20%;
    align-items: center;
    .MuiCheckbox-root {
      padding: 0;
    }
  }
  .upload-container {
    height: 190px;
    border: 1px dashed lightblue;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .hidden-input {
    display: none;
  }
  #product-modal {
    .upload-item-full-size {
      margin-bottom: 50px;
    }
    button {
      cursor: pointer;
    }
    .button-wrapper {
      justify-content: flex-end;
      display: flex;
      margin-top: 10px;
      position: absolute;
    }
    img {
      max-height: 180px;
    }
    .button-submit {
      position: absolute;
      right: 30px;
      bottom: 30px;
    }
  }
}
.editorClassName {
  z-index: 1;
  position: relative;
  font-size: 13px;
  border-radius: 5px;
  padding: 0 20px;
  background: transparent;
  color: #333e48;
  transition: all 0.2s ease;
  width: 100%;
  min-height: 200px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.rdw-editor-main {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}
.rdw-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  user-select: none;
}
.rdw-editor-wrapper {
  box-sizing: content-box;
}
.news-button {
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
}
.inner-html {
  word-break: break-all;
}
