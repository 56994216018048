.search {
  margin: 20px;
  height: 40px;
  width: 300px;
  display: flex;
  outline: none;
  padding: 0 10px;
  border-radius: 10px;
}

.input-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
}
#visibleOnSite {
  .proizvodi,
  .nav_menu_proizvodi {
    grid-template-columns: 20% 50% 30%;
  }
}
