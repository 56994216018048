.box-shadow {
  box-shadow: 0px 0px 9px -5px #251e18 !important;
}
.swiper-custom-navigation {
  //   .swiper-scrollbar {
  //     display: flex;
  //     justify-content: center;
  //     width: 30%;
  //     margin: 0 auto;
  //     height: 50px;
  //     background: none !important;
  .swiper-scrollbar {
    bottom: 30px;
    @media screen and (max-width: 425px) {
      bottom: 0;
    }
  }
  .swiper-scrollbar-drag {
    //       border-radius: 100%;
    //       height: 20px;
    //       width: 20px !important;
    background: #fb923c;
  }
  //   }
}
