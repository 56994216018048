.swiper_product_name {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  justify-content: center;
  @media screen and (max-width: 425px) {
    min-width: 220px;
  }
}
.product_name {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 425px) {
    font-size: 14px;
  }
}
.old_price {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: line-through;
}
.price {
  font-weight: 600;
  font-size: 22px;
  line-height: 38px;
  color: #a6ce38;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}
.save_money {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

#swiper-products {
  .swiper-wrapper {
    display: flex;
    justify-content: center;
  }
  .rounded {
    .py-4 {
      padding-bottom: 0;
    }
  }
}
.swiper_produt {
  margin-top: 25px;
  margin-bottom: -90px;
  @media screen and (max-width: 768px) {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
}

.swiper-item-size {
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
  @media screen and (max-width: 425px) {
    width: 100px !important;
    height: 180px !important;
  }
}

.custom-slider {
  @media screen and (max-width: 425px) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
}

.baner-image {
  width: 75%;
  @media screen and (max-width: 425px) {
    width: 100%;
  }
}

.name-wrapper {
  display: flex;
  align-items: center;
  hr {
    width: 100%;
    border-color: #f5821f;
  }
  .swiper_product_name {
    min-width: max-content;
    margin: 0 15px;
    @media screen and (max-width: 425px) {
      min-width: 220px;
    }
  }
}
