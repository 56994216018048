.category {
  padding: 10px 20px;
  background: #f0f0f0;
}

.child-row,
.header-row {
  display: flex;
  justify-content: space-between;
}
.header-row {
  padding: 10px 20px;
  font-weight: bold;
}
.table {
  div:nth-child(even) {
    background: #f8f8f8;
    .action-item {
      background: #f8f8f8;
    }
  }
  div:nth-child(odd) {
    .action-item {
      background: #f0f0f0;
    }
  }
}

.action-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid black;
  padding: 0px 5px;
  border-radius: 10px;
}
.first-action {
  margin-right: 15px;
}
.description {
  border: 1px dashed lightblue;
  outline: none;
  width: 90%;
  margin: 0 auto;
  padding: 10px;
}
