#action-items {
  .action-items-wrapper {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 425px) {
      grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }

    @media screen and (max-width: 424px) {
      grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    }
  }
  .action-item {
    width: 20%;
    border: none;
    justify-content: center;
    padding: 20px;
    border-bottom: 1px solid #bfbfbf;
    border-radius: 0;
    @media screen and (max-width: 768px) {
      width: 50%;
    }
    @media screen and (max-width: 424px) {
      width: 100%;
    }
  }
  .swiper_product_name {
    padding: 50px 0;
    justify-content: center;
    @media screen and (max-width: 425px) {
      min-width: 220px;
    }
  }
  .banner-image {
    height: 350px;
    width: 100%;
    margin-top: 15px;
  }
}
.name-wrapper {
  display: flex;
  align-items: center;
  hr {
    width: 100%;
    border-color: #f5821f;
  }
  .swiper_product_name {
    min-width: max-content;
    margin: 0 15px;
    @media screen and (max-width: 425px) {
      min-width: 220px;
    }
  }
}
