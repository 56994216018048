.search_wrapper {
  width: 70%;
  .input_field {
    display: flex;
    width: 100%;
    position: relative;
    input {
      border: 1px solid black;
      height: 34px;
      width: 100%;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 0 5px 20px;
    }
    svg {
      position: absolute;
      right: 5px;
      top: 3px;
      font-size: 27px;
    }
  }
  .input_results {
    position: absolute;
    width: 37%;
    @media screen and (max-width: 425px) {
      width: 100%;
      left: 0;
      top: 160px;
    }
    overflow: hidden;
    overflow-y: auto;
    background-color: #f3f3f3;
    z-index: 11;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.right_side {
  position: relative;
  .icons {
    position: absolute;
    top: 0;
    svg {
      font-size: 20px;
      margin: 5px;
    }
  }
}
.all_categories {
  border-radius: 5px 5px 0 0;
  color: white;
  cursor: pointer;
  height: 40px;
  padding: 0 20px;
  @media screen and (max-width: 768px) {
    width: 200px;
    margin-left: 5px;
  }
  @media screen and (max-width: 710px) {
    width: 230px !important;
  }
  p {
    padding: 0 10px;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
  }
}
.actions_type {
  p {
    cursor: pointer;
    padding: 0 15px;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
  }
  @media screen and (max-width: 425px) {
    margin-left: 0;
    width: 100%;
    margin-bottom: 15px;
    justify-content: space-between;
    p {
      width: max-content !important;
      padding: 0;
    }
  }
  .new_products {
    @media screen and (max-width: 770px) {
      display: none;
    }
  }
  .contact_link {
    @media screen and (max-width: 770px) {
      display: none;
    }
  }
}
.img-smaller {
  width: 66%;
}
.img-full-size {
  width: 100%;
}
.add-transition {
  transition: 0.5s all;
}

.login {
  @media screen and (max-width: 425px) {
    margin-top: 35px;
  }
}
