.product_details-zoom {
  position: relative;
  &:hover {
    min-height: 330px;
  }
  .rounded {
    &:hover {
      @media screen and (min-width: 425px) {
        transform: scale(1.1);
      }
      padding: 10px;
      background-color: white;
      position: absolute;
      z-index: 999;
      box-shadow: 0 0 20px -11px rgb(68, 58, 58);
      transition: 0.2s all;
    }
  }
  border-bottom: 1px solid #bfbfbf;
}
.info_values {
  margin: 0;
  padding: 0;
}
.posts-page {
  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  @media screen and (max-width: 375px) {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}
