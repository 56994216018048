.search_wrapper {
  .input_results {
    .search_img {
      #img {
        background-position: center !important;
        background-size: contain !important;
        height: 130px;
        width: 130px;
        background-repeat: no-repeat !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .search_wrapper {
    width: 80%;
    display: flex;
    align-items: center;
    svg {
      font-size: 35px;
    }
    .input_field {
      width: 100%;
    }
    @media screen and (max-width: 425px) {
      justify-content: space-between;
      margin-top: 20px;
      width: 100%;
      .input_field {
        padding-left: 20px;
      }
    }
  }
}
.search-item {
  border: 1px solid #f3f3f3;
  background: linear-gradient(to right, #fff 30%, #fafafa 50%, #f3f3f3 70%);
  p {
    margin: 0;
    padding: 0;
    line-height: 1;
  }
  justify-content: space-between;
  padding: 10px;
  div {
    justify-content: center;
    display: flex;
    img {
      max-width: 130px;
    }
  }
  .search_img {
    width: 30%;
  }
  .search_details {
    width: 65%;

    flex-direction: column;
  }
  .product_name {
    font-weight: 600;
    margin-bottom: 15px;
    height: auto;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
  .old_price {
    color: gray;
    font-size: 13px;
  }
}
.input_field {
  width: 600px;
}
.input_results {
  max-height: 570px;
}
.item-wrapper {
  border-bottom: 1px solid #c4baba;
}
.show-more {
  text-align: right;
  margin-right: 20px;
}
