// .show-products-size {
//   @media screen and (max-width: 768px) {
//     max-height: 100px !important;
//   }
// }
.show-product-size {
  @media screen and (max-width: 768px) {
    // max-height: 100px !important;
    padding-top: 0rem !important;
  }
}
.name-wrapper {
  display: flex;
  align-items: center;
  hr {
    width: 100%;
    border-color: #f5821f;
  }
  .swiper_product_name {
    min-width: max-content;
    margin: 0 15px;
    @media screen and (max-width: 425px) {
      min-width: 220px;
    }
  }
}
.product_details-zoom {
  position: relative;
  &:hover {
    min-height: 330px;
  }
  .rounded {
    &:hover {
      @media screen and (min-width: 425px) {
        transform: scale(1.1);
      }
      padding: 10px;
      background-color: white;
      position: absolute;
      z-index: 999;
      box-shadow: 0 0 20px -11px rgb(68, 58, 58);
      width: 310px;
      transition: 0.2s all;
    }
  }
  border-bottom: 1px solid #bfbfbf;
}

.show-product-connected {
  width: 20%;
  @media screen and (max-width: 768px) {
    width: 50%;
  }
}
.sticker-img {
  @media screen and (max-width: 425px) {
    width: 100% !important;
  }
}
