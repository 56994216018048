.category_product {
  background-color: #f3f3f3;
  width: 286px;
  // height: 137px;
  text-align: center; //skinuti ovo
  margin-bottom: 10px; //skinuti kad vracamo slike
  height: 50px;
  @media screen and (max-width: 768px) {
    width: auto;
  }
  @media screen and (max-width: 425px) {
    width: auto;
  }
}

.dataResults {
  overflow-y: scroll;
  scroll-behavior: smooth;
  width: 39%;
  height: 16%;
  position: absolute;
  z-index: 9;
  background-color: white;

  .searchDetails {
    width: 100%;
    display: flex;
    color: black;
    height: 150px;
    overflow: hidden;
    align-items: center;
    .search_img {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}
.dataResults::-webkit-scrollbar {
  display: none;
}
.dataResults {
  z-index: 10;
  position: absolute;
  top: 50px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  .search_img {
    height: 100px;
    width: 100px;
  }
}
.searchInput {
  position: relative;
  input {
    padding: 5px 25px;
    border-radius: 5px;
  }
  .searchIcon {
    position: absolute;
    right: 0;
    svg {
      font-size: 45px;
    }
  }
}

.pagination {
  font-size: 25px;
  display: flex;
  justify-content: end;
  align-items: center;
  a.active {
    font-size: 35px;
    color: #a6ce38;
  }
}
