.contact_form {
  form {
    input,
    textarea {
      border: 1px solid black;
      border-radius: 5px;
      padding: 4px 25px;
      &:focus {
        border: 1px solid rgb(251, 146, 60);
        outline: #a6ce38;
      }
    }
  }
  .submit {
    background-color: #a6ce38;
    width: 140px;
    border-radius: 5px;
    color: white;
    height: 35px;
  }
  .contact_us {
    font-family: 500;
    font-size: 20px;
    line-height: 30px;
  }
}
.contact-img {
  margin-right: 10px;
}
.item-contact-wrapper {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
