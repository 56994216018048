.admin_header {
  box-shadow: 0px 25px 20px -20px rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .admin_icon {
    img {
      margin-left: 70px;
    }
  }
  .admin_acount {
    display: flex;
    align-items: center;
    margin-right: 20px;
    p,
    h4 {
      padding: 0 10px;
    }
    .bell {
      font-size: 25px;
      margin-right: 20px;
    }
    img {
      width: 40px;
    }
    svg {
      font-size: 40px;
    }
  }
}
