#news {
  width: 80%;
  margin: 60px auto;
  @media screen and (max-width: 768px) {
    margin: 0 !important;
    width: 100%;

    .swiper {
      width: 100%;
    }
  }
  .swiper-scrollbar {
    bottom: 0;
  }
  .swiper-slide {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #f5821f;
    border-radius: 10px;
  }
  .heading {
    margin: 20px 0;
    font-weight: 600;
    font-size: 1.3rem;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .date {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: gray;
  }
  .desc {
    color: rgba(0, 0, 0, 0.7);
    line-height: 1.7;
  }
  .divider {
    margin: 10px;
    background: #f5821f;
  }
  .swiper-wrapper {
    margin-bottom: 40px;
  }
}
.name-wrapper {
  display: flex;
  align-items: center;
  color: #333e48;

  hr {
    width: 100%;
    border-color: #f5821f;
  }
  .swiper_product_name {
    min-width: max-content;
    margin: 0 15px;
    @media screen and (max-width: 425px) {
      min-width: 220px;
    }
  }
}
