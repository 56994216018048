.search {
  margin: 20px;
  height: 40px;
  width: 300px;
  display: flex;
  outline: none;
  padding: 0 10px;
  border-radius: 10px;
}

.input-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
}

.proizvodi,
.nav_menu_proizvodi {
  grid-template-columns: 10% 20% 40% 20% 10%;
  align-items: center;
  .MuiCheckbox-root {
    padding: 0;
  }
}
.upload-container {
  height: 190px;
  border: 1px dashed lightblue;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.hidden-input {
  display: none;
}
#product-modal {
  .upload-item-full-size {
    margin-bottom: 50px;
  }
  button {
    cursor: pointer;
  }
  .button-wrapper {
    justify-content: flex-end;
    display: flex;
    margin-top: 10px;
    position: absolute;
  }
  img {
    max-height: 180px;
  }
  .button-submit {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
}
