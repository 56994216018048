.slider-img {
  height: 200px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;

  img {
    max-width: 90%;
  }
}
#custom-slider {
  // &.swiper {
  //   padding: 0 30px !important;
  // }

  // .swiper-button-prev {
  //   margin-left: 0 !important;
  //   background: white;
  //   height: 100%;
  //   top: 20px;
  //   left: 0;
  // }
  // .swiper-button-next {
  //   margin-right: 0 !important;
  //   background: white;
  //   height: 100%;
  //   top: 20px;
  //   right: 0;
  // }
  .swiper-slide {
    // width: calc(100% / 7) !important;
    // @media screen and (max-width: 1024px) {
    //   width: calc(100% / 6) !important;
    // }
    // @media screen and (max-width: 768px) {
    //   width: calc(100% / 6) !important;
    // }
    // @media screen and (max-width: 425px) {
    //   width: 50% !important;
    // }
    display: flex;
    justify-content: center;
    margin-right: 0 !important;
    align-items: center;
    margin: 0 5px;
  }
  .swiper {
    padding: 0 30px;
  }
}
.brands-size {
  @media screen and (max-width: 768px) {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
}
.name-wrapper {
  display: flex;
  align-items: center;
  hr {
    width: 100%;
    border-color: #f5821f;
  }
  .swiper_product_name {
    min-width: max-content;
    margin: 0 15px;

    @media screen and (max-width: 425px) {
      min-width: 220px;
    }
  }
}
