.all_content{
    background-color: rgb(240, 240, 240);
    position: absolute;
    top: 100px;
    left: 300px;
    width: calc(100% - 350px);
    border: 1px solid rgb(240, 240, 240);
    border-radius: 5px;
    .nav_menu_proizvodi{
        div{
            padding: 0 10px;
            p{
                font-weight: bold;
            }
        }
    }
    .proizvodi{
        div{
            padding: 0 10px;
            p{
                font-size: 12px;
            }
        }
    }
    .proizvodi:nth-child(even){
            background-color: #f8f8f8;
        }
    .proizvodi:nth-child(odd){
            background-color: rgb(240, 240, 240);
        }

        .category{
            p{
                cursor: pointer;
            }
        }
    
    
}