#actions {
  .category {
    padding: 10px 20px;
    background: #f0f0f0;
  }

  .child-row,
  .header-row {
    display: flex;
    justify-content: space-between;
    p {
      width: 50%;
    }
  }

  .table {
    width: 100%;

    div:nth-child(even) {
      background: #f8f8f8;
    }
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .action-items {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .action-item {
    max-width: 90px;
    text-align: center;
    margin-right: 5px;
    justify-content: center;
  }
}
.error-text {
  color: red;
  margin-bottom: 5px;
}
