.MuiSnackbar-anchorOriginBottomCenter {
  position: fixed !important;
  top: 20px !important;
  right: 20px !important;
  left: auto !important;
  bottom: auto !important;
  transform: none !important;
}
.MuiAlert-message {
  background: transparent !important;
}
