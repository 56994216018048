.footer {
  color: white;
  background-color: #a6ce38;
  .footer_all_categories {
    a {
      display: flex;
      flex-direction: column;
      font-weight: 400;
      font-size: 16px;
      width: 50%;
    }
    .footer-categories {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .usuful_links {
    ul {
      li {
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}
.footer-wrapper-items {
  grid-template-columns:
    repeat(1, minmax(0, 2fr)) repeat(1, minmax(0, 4fr)) repeat(
      1,
      minmax(0, 2fr)
    )
    repeat(2, minmax(0, 3fr));
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    div {
      margin-left: 10px;
    }
  }
}
.contact {
  position: relative;
  .newsletter {
    display: flex;
    height: 34px;
    input {
      position: relative;
      width: 100%;
      border-radius: 5px;
      background-color: #a6ce38;
      border: 1px solid white;
      color: white;
      padding: 8px 13px;
      &::placeholder {
        color: white;
      }
    }
    button {
      position: absolute;
      right: 0;
      background-color: white;
      height: 34px;
      width: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      svg {
        font-size: 25px;
        color: #a6ce38;
      }
    }
  }
  .icons {
    padding-top: 10px;
    svg {
      font-size: 25px;
      margin: 0 5px;
    }
  }
}
.bottom_footer {
  background-color: #414143;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  .jakov {
    color: #a6ce38;
  }
}

@media screen and (max-width: 770px) {
  .footer {
    text-align: center;
  }
}
.bottom-footer-size {
  @media screen and (max-width: 768px) {
    padding: 10px;
  }
}
.as_logo-img {
  padding: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
