.sticker-img {
  @media screen and (max-width: 425px) {
    width: 100% !important;
  }
}
.add_to_cart {
  background-color: #a6ce38;
  color: white;
  border-radius: 4px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  gap: 10px;
}
.add {
  gap: 10px;
  width: fit-content !important;
  display: flex;
  justify-content: space-evenly;
  .add_to_cart {
    background-color: #a6ce38;
    color: white;
    border-radius: 4px;
    text-wrap: nowrap;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .quantity {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    display: grid;
    border-radius: 5px;
    grid-template-columns: 40px 60px 40px;
    border: 1px solid #a6ce38;
    input,
    div {
      height: 100%;
      display: flex;
      font-size: large;
      text-align: center;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
    }
    input {
      border-right: 1px solid #a6ce38;
      text-align: center;
      width: 60px;
      border-left: 1px solid #a6ce38;
    }
    div {
      font-size: xx-large;
    }
  }
}
.single_product {
  .left_single_product {
    width: 70%;
    img {
      // width: 250px;
      height: auto;
      max-height: 250px;
    }
  }
  .right_single_product {
    .monthly_offer {
      text-align: center;
      border-radius: 4px;
      color: white;
      padding: 5px;
      width: 50%;
      background-color: #f5821f;
      @media screen and (max-width: 425px) {
        width: 100%;
      }
    }
    .single_product_name {
      font-weight: 500;
      font-size: 34px;
      line-height: 51px;
    }
    .pdv {
      font-family: 300;
      font-size: 14px;
      line-height: 21px;
      color: #bfbfbf;
    }
  }
  .info_product {
    display: flex;
    border-bottom: 1px solid black;
    p {
      font-weight: 400;
      font-size: 22px;
      line-height: 33px;
      margin-left: 100px;
    }
  }
  .right_img {
    width: 70%;
    height: 70%;
    img {
      // width: 250px;
      height: 250px;
    }
  }
}
.product_name {
  font-weight: 600;
  font-size: 16px;
}
.old_price {
  color: gray;
}
.left_single_product {
  position: relative;
}
.zoom-image {
  position: absolute;
  bottom: 0;
  background: #0000008f;
  color: white;
  padding: 0 10px;
  cursor: pointer;
}
