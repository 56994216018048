.left_list {
  position: relative;
  display: flex;
  .list_categories {
    width: 100%;
    .list {
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: #fcfcfc;
      a {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .hide-on-mobile {
    @media screen and (max-width: 425px) {
      display: none;
    }
  }
  .dropdown_list_item {
    min-height: 470px;
    position: absolute;
    z-index: 9;
    background-color: #f3f3f3;
    width: 200%;
    a {
      margin: 5px 0 5px 5px;
    }
    .baner_link {
      margin: 0;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    @media screen and (max-width: 425px) {
      display: none;
    }
  }
}
.subcategory-name {
  min-width: 320px;
  max-width: 320px;
}
.brands {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}
.our_offer {
  @media screen and (max-width: 770px) {
    div {
      margin: 0 70px;
    }
  }
  @media screen and (max-width: 425px) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .our-offer-mobile {
      margin: 0;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      display: flex;
      height: 70px;
    }
  }
}
.category-link {
  :hover {
    color: #fb923c;
  }
  &.active {
    .flex {
      color: #fb923c;
    }
  }
  .flex {
    align-items: center;
    // padding-left: 10px;
    // img {
    //   // margin-right: 10px;
    // }
  }
}

.active-subcategory {
  margin-left: 50px;

  &:hover {
    color: #fb923c;
  }
}
.category-banner {
  min-height: 525px;
  max-height: 525px;
}
.hide-on-mobile {
  @media screen and (max-width: 425px) {
    display: none;
  }
}
.categories-list {
  width: 349px;
  @media screen and (max-width: 1024px) {
    width: 216.75px;
  }
  @media screen and (max-width: 768px) {
    width: 200px;
  }
}

.cat-menu-icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}
