.show-menu {
  position: absolute;
  margin-top: 40px;
  background: #fff;
  z-index: 99999999;
  width: 349px;
  box-shadow: 0px 4px 8px -4px black;
  min-height: 540px;
  @media screen and (max-width: 1024px) {
    width: 216.75px;
  }
  @media screen and (max-width: 768px) {
    width: 250px;
  }
  @media screen and (max-width: 425px) {
    top: 0px;
    margin-top: 0;
    width: 100vw;
    height: 100vh;
  }
}
.hide-elem {
  display: hidden;
}
.button-close-mobile {
  display: none;
  @media screen and (max-width: 425px) {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
}
.home-icon {
  width: 25px;
  height: 25px;
  font-size: 18px;
  display: flex;
  flex-shrink: 1;
  margin-right: 5px;
  color: #fb923c;
}
.link-item {
  display: flex;
}
.on-hover:hover,
.active-menu-item {
  color: #fb923c;
  text-decoration: underline;
}
.full-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
}
.full-header-margin {
  margin: 0 -10px;
}
.on-hover-white {
  p:hover {
    color: #fff;
  }
  .active-menu-item {
    color: #fff;
    text-decoration: underline;
  }
}
.add-transition {
  transition: 0.5s all;
}

.all_categories {
  @media screen and (max-width: 711px) {
    width: 230px;
    padding: 0;
  }
  @media screen and (max-width: 650px) {
    width: 200px;
    padding: 0;
  }
}
