#custom-slider {
  &.swiper {
    padding: 0 30px !important;
    // @media screen and (max-width: 768px) {
    //   padding: 0 !important;
    // }
  }

  .swiper-button-prev {
    margin-left: -10px;
    height: 100%;
    top: 0;
    color: #a6ce38;
    background: white;
    width: 30px;
    margin-top: 0;
  }
  .swiper-button-next {
    margin-right: -10px;
    height: 100%;
    top: 0;
    color: #a6ce38;
    background: white;
    width: 30px;
    margin-top: 0;
  }
}
// .categories-swiper {
//   .swiper-slide {
//     width: calc(100% / 7) !important;
//   }
// }
.categories-size {
  @media screen and (max-width: 768px) {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
}
.categoreies-container-size {
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.name-wrapper {
  display: flex;
  align-items: center;
  hr {
    width: 100%;
    border-color: #f5821f;
  }
  .swiper_product_name {
    min-width: max-content;
    margin: 0 15px;
    @media screen and (max-width: 425px) {
      min-width: 220px;
    }
  }
}
