.opacity {
  .bg_opacity {
    position: fixed;
    background-color: black;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 2;
    left: 0;
    top: 0;
  }
  .catalog {
    z-index: 9;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .close_catalog_btn {
      z-index: 99;
      position: absolute;
      color: white;
      top: 15px;
      right: 15px;
      width: 50px;
      height: 50px;
      background-color: transparent;
    }
    .take_baner {
      position: absolute;
      background-color: transparent;
      top: 10%;
      right: 0%;
      width: 100%;
      height: 90%;
    }
  }
}

.catalog-image {
  min-width: 100%;
  min-height: 500px;
}
