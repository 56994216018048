a {
  outline: none;
}
#custom-slider {
  &.swiper {
    padding: 0 30px !important;
    // @media screen and (max-width: 768px) {
    //   padding: 0 !important;
    // }
  }

  .swiper-button-prev {
    margin-left: -10px;
    height: 100%;
    top: 0;
    background: white;
    width: 30px;
    color: #a6ce38;
    margin-top: 0;
    // @media screen and (max-width: 768px) {
    //   display: none;
    // }
  }
  .swiper-button-next {
    margin-right: -10px;
    height: 100%;
    top: 0;
    background: white;
    width: 30px;
    color: #a6ce38;
    margin-top: 0;

    // @media screen and (max-width: 768px) {
    //   display: none;
    // }
  }
}

.pagination-mui {
  nav {
    display: flex;
    justify-content: flex-end;
    margin: 10px 10px 40px 10px;
  }
}

.edit-action-item {
  border: none !important;
  background: #a6ce38 !important;
  border-radius: 5px !important;
  color: #fff !important;
}

.sub-action-item {
  border: none !important;
  background: #2e7d32 !important;
  border-radius: 5px !important;
  color: #fff !important;
}
.delete-action-item {
  border: none !important;
  background: #fb923c !important;
  border-radius: 5px !important;
  color: #fff !important;
}
.swiper-button-prev {
  margin-left: -10px;
  height: 100%;
  top: 0;
  color: #a6ce38;
  background: white;
  width: 30px;
  margin-top: 0;
  // @media screen and (max-width: 768px) {
  //   display: none;
  // }
}
#custom-slider-products {
  &.swiper {
    padding: 0 30px !important;
    // @media screen and (max-width: 768px) {
    //   padding: 0 !important;
    // }
  }
}
.swiper-button-next {
  margin-right: -10px;
  height: 100%;
  top: 0;
  color: #a6ce38;
  background: white;
  width: 30px;
  margin-top: 0;
  // @media screen and (max-width: 768px) {
  //   display: none;
  // }
}
img {
  @media screen and (max-width: 768px) {
    height: max-content;
  }
}

.swiper {
  align-items: center;
  @media screen and (max-width: 768px) {
    align-items: flex-start;
  }
}

.header-items {
  grid-template-columns: repeat(1, minmax(0, 1fr)) 140px repeat(
      3,
      minmax(0, 1fr)
    );
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    .click-items {
      width: 80px;
      text-align: center;
    }
  }
  @media screen and (max-width: 425px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.categories-page {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
.category-products-page {
  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  @media screen and (max-width: 375px) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
.action-items-page {
  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
.single_product-item {
  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    .img-wrapper {
      margin-bottom: 10px;
    }
  }
  .sticker-img {
    @media screen and (max-width: 425px) {
      width: 100% !important;
    }
  }
}
.number-item {
  @media screen and (max-width: 425px) {
    display: none;
  }
}
// .categories-swiper {
//   .swiper-slide {
//     // width: calc(100% / 7) !important;
//   }
// }
// .show-product-size {
//   @media screen and (max-width: 768px) {
//     max-height: 100px !important;
//     padding-top: 0rem !important;
//   }

// }
#root {
  @media screen and (max-width: 768px) {
    margin: 0 10px;
  }
}

.categories-item-size {
  width: 25%;
  @media screen and (max-width: 425px) {
    width: 50%;
  }
}

.MuiBreadcrumbs-ol {
  @media screen and (max-width: 425px) {
    justify-content: center;
    margin-top: 15px;
  }
}
.swiper-product-item {
  @media screen and (max-width: 425px) {
    padding-left: 20px;
    word-break: break-word;
  }
}

.product_details-zoom {
  position: relative;

  &:hover {
    min-height: 330px;
  }
  .rounded {
    &:hover {
      @media screen and (min-width: 425px) {
        transform: scale(1.1);
      }
      padding: 10px;
      background-color: white;
      position: absolute;
      z-index: 99999;
      box-shadow: 0 0 20px -11px rgb(68, 58, 58);
      width: 310px;
      transition: 0.2s all;
    }
  }
  border-bottom: 1px solid #bfbfbf;
}
.image-as-background {
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}
.main-container {
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0 10px;
    margin: 0;
    max-width: 100%;
  }
}
